<template>
  <div>
    <p class="title-secondary m__bottom--20">{{ title || $t('user_service.page.create') }}</p>
    <div class="page-main-content mt-0" @keyup.enter="submit()">

      <div class="columns">
        <div class="column is-2 d-flex align-items-center">
          <span class="input-title">{{ $t('user_service.input_field.id.label') }}<i
              :data-tooltip="$t('tooltip.required')"
              class="required-note">*</i></span>
        </div>
        <div class="column is-7">
          <label>
            <input type="text" :disabled="isUpdate"
                   :class="{'is-error': ((!vuelidate.userService.code.required || !vuelidate.userService.code.XSSCheck || !vuelidate.userService.code.maxLength ) && vuelidate.$dirty) || (error_server.code && error_server.code.length)}"
                   :placeholder="$t('user_service.input_field.id.placeholder')"
                   v-model.trim="userService.code"
                   class="input">
          </label>
          <span class="error pt-1" v-if="error_server.code && error_server.code.length">
            {{ error_server.code[0] }}
          </span>
          <template v-else-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.userService.code.required">
            {{ $t('user_service.validation.code.required') }}
            </span>
            <span class="error pt-1" v-else-if="!vuelidate.userService.code.XSSCheck">
            {{ $t('user_service.validation.code.xss_check') }}
            </span>
            <span class="error pt-1" v-else-if="!vuelidate.userService.code.maxLength">
            {{ $t('user_service.validation.code.maxlength') }}
            </span>
          </template>
        </div>
      </div>

      <div class="columns">
        <div class="column is-2 d-flex align-items-center">
          <span class="input-title">{{ $t('user_service.input_field.name.label') }}<i
              :data-tooltip="$t('tooltip.required')"
              class="required-note">*</i></span>
        </div>
        <div class="column is-7">
          <label>
            <input type="text"
                   :class="{'is-error': ((!vuelidate.userService.name.required || !vuelidate.userService.name.maxLength) && vuelidate.$dirty) || (error_server.name && error_server.name.length)}"
                   :placeholder="$t('user_service.input_field.name.placeholder')"
                   v-model.trim="userService.name"
                   class="input">
          </label>
          <span class="error pt-1" v-if="error_server.name && error_server.name.length">
            {{ error_server.name[0] }}
          </span>
          <template v-else-if="vuelidate.$dirty">
            <span class="error pt-1" v-if="!vuelidate.userService.name.required">
            {{ $t('user_service.validation.name.required') }}
            </span>
            <span class="error pt-1" v-else-if="!vuelidate.userService.name.maxLength">
            {{ $t('user_service.validation.name.maxlength') }}
            </span>
          </template>

        </div>
      </div>

      <div class="columns">
        <div class="column is-full">
          <div class="field is-grouped is-grouped-right">
            <div class="control">
              <button type="submit" @click="submit()" class="button btn-save background__pink">
                {{ buttonText || $t('user_service.buttons.save') }}
              </button>
            </div>
            <div class="control">
              <button @click="backToList()" class="button btn-back background__grey">{{
                  $t('buttons.cancel')
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ACTION_SET_ACTIVE_SIDEBAR} from "@/stores/common/actions";
import {required, maxLength} from "vuelidate/lib/validators";
import {UserUsageService} from "@/services";
import {XSSCheck} from "../../helpers/utils";

export default {
  name: "UserServiceCreate",
  props: {
    userServiceUpdate: {
      type: Object,
      default: () => {
      },
    },
    title: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      userService: {
        code: '',
        name: ''
      },
      isUpdate: false,
      error_server: {},
      submitAvailable: true,
    }
  },
  validations: {
    userService: {
      code: {
        required,
        maxLength: maxLength(20),
        XSSCheck: (val) => {
          return XSSCheck(val)
        }
      },
      name: {
        required,
        maxLength: maxLength(50),
      }
    }
  },
  watch: {
    userServiceUpdate(val) {
      if (val) {
        this.userService = {...val}
        this.isUpdate = true
      } else {
        this.isUpdate = false
      }
    }
  },
  methods: {
    submit() {
      this.error_server = {}
      this.vuelidate.$touch();
      if (!this.vuelidate.$invalid && this.submitAvailable) {
        this.submitAvailable = false
        if (this.isUpdate) {
          UserUsageService.update(this.userService.id, this.userService)
              .then(() => {
                this.submitAvailable = true
                this.$toast.success(this.$t('user_service.messages.update_success'))
                this.$router.push({name: 'UserService'}, () => {
                })
              })
              .catch((err) => {
                this.submitAvailable = true
                this.error_server = err.data.errors
                // this.$toast.error(err.data.message)
              })
        } else {
          UserUsageService.create(this.userService)
              .then(() => {
                this.submitAvailable = true
                this.$toast.success(this.$t('user_service.messages.create_success'))
                this.$router.push({name: 'UserService'}, () => {
                })
              })
              .catch((err) => {
                this.submitAvailable = true
                this.error_server = err.data.errors
                // this.$toast.error(err.data.message)
              })
        }
      }
    },
    backToList() {
      this.$router.push({name: 'UserService'}, () => {
      })
    }
  },
  created() {
    this.$store.dispatch(ACTION_SET_ACTIVE_SIDEBAR, 'user-service')
  }
}
</script>

<style lang="scss" scoped>

</style>